/* In your global CSS file (e.g., styles.css) */

.custom-list ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    /* Adjust the padding as needed */
}

.custom-list ul li {
    margin-bottom: 0.3rem;
    font-weight: 300
    /* Adjust the margin as needed */
}

.custom-list strong {
    font-weight: 600;
    /* Adjust the margin as needed */
}

.custom-list p {
    font-weight: 300;
    /* Adjust the margin as needed */
}