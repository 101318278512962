.scoped-phone-input .PhoneInputInput {
    padding: 1rem;
    font-size: 18px;
    font-weight: 500;
}


/* Custom scrollbar CSS */

.custom-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.custom-scrollbar {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}